.App {
  /* text-align: center;*/
  background-color: #f8f8f8;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/* a{
  cursor: pointer;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.button--file input {
  cursor: pointer;
  height: 100%;
  right: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 300px;
  z-index: 4;
}

.textfield--file input {
  box-sizing: border-box;
  width: calc(100% - 32px);
}
.textfield--file .button--file {
  right: 0;
}

.btn-tool-text-file {
  justify-content: stretch;
}

.hover-hand {
  cursor: pointer;
}

.card-header {
  border-bottom: none;
  background-color: white;
  padding-bottom: 0;
}

.accordion > .card > .card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth > div > div.react-datepicker__input-container .customDatePickerWidth > div > div.react-datepicker__input-container input {
  width: 100%;
}

.sticky-header-table {
  width: 100%;
}

.scroll {
  display: table;
  table-layout: fixed;
  max-height: 420px;
  overflow-y: auto;
}

.sticky-header,
.scroll tr {
  display: table;
  /* width: 1250px; */
  width: 100%;
  table-layout: fixed;
}

@media only screen and (max-width: 1050px) {
  .sticky-header,
  .scroll tr {
    width: 1250px;
  }
}

.status-column {
  width: 190px !important;
}

#uncontrolled-tab-example-tab-car,
#uncontrolled-tab-example-tab-maintenance {
  font-size: 20px;
  font-weight: 500;
}

@media only screen and (max-width: 1200px) {
  .modal-90w {
    max-width: 95%;
    margin: 1.75rem auto;
  }
}

.modal-10w {
  max-width: 95%;
  margin: 1.75rem auto;
}

.offlineModal .modal-content {
  background-color: red;
}

.rbc-day-bg {
  position: relative;
}

.rbc-day-bg:hover {
  background-color: #f0f0f0;
}

.rbc-day-bg:hover::after {
  content: "+";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4.5rem;
  color: #3174ad;
  z-index: 1;
  display: inline-block;
}
